import React, { Suspense } from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from "./App";
import { store } from "./store/store";
import PersistProvider from "./store/providers/persist-provider";
import { setProducts } from "./store/slices/product-slice";

// Styles and additional imports
import 'animate.css';
import 'swiper/swiper-bundle.min.css';
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "./assets/scss/style.scss";
import "./i18n";

const Cookies = {
  get: (name) => {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    return match ? decodeURIComponent(match[2]) : null;
  },
  
  set: (name, value, days = 7) => {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    document.cookie = `${name}=${encodeURIComponent(value)};expires=${date.toUTCString()};path=/`;
  },
  
  remove: (name) => {
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
  }
};

// Loading component
const LoadingScreen = () => (
  <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    fontSize: '1.5rem'
  }}>
    Loading products...
  </div>
);

// Main app wrapper that handles initialization
const AppWrapper = ({ children }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    const initializeApp = async () => {
      try {
        let brand = Cookies.get('brand');
const pathSegments = window.location.pathname.split('/').filter(segment => segment.length > 0);
var  brand1 = ''
        if (!brand) {
          if (window.location.pathname.includes('/cat/'))  {
   brand1 = pathSegments[pathSegments.length - 2];
          }else{
   brand1 = pathSegments[pathSegments.length - 1];

          }
  console.log(pathSegments)
  // Get domain from current URL
  const hostname = window.location.hostname;
  const domain = hostname.split('.').slice(-2).join('.');  // Gets "oem-creoo.com" from any subdomain
  
  const expiryDate = new Date();
  expiryDate.setFullYear(expiryDate.getFullYear() + 10);
  
  // Set new cookie with dynamic domain
  document.cookie = `brand=${brand1};expires=${expiryDate.toUTCString()};path=/;domain=shop.${domain};SameSite=Lax`;
  console.log('setting cookie')
  console.log(brand1)


        }
        //brand = 'Bep'
        if (brand) {


      //  const response = await fetch(`https://b1ab9cf0-3155-490f-b5c5-8f7aca34dd82.clouding.host/api/products/${brand}`);
        const response = await fetch(`https://b1ab9cf0-3155-490f-b5c5-8f7aca34dd82.clouding.host/api/products/bypass/${brand}`);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const products = await response.json();
        console.log('products loaded:', products);
        
        // Dispatch products to store
        store.dispatch(setProducts(products));
          }
        
        // Only set loading to false after products are in store
        setIsLoading(false);
      } catch (err) {
        console.error('Failed to initialize app:', err);
        setError(err.message);
        setIsLoading(false);
      }
    };

    initializeApp();
  }, []);

  if (error) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        color: 'red',
        fontSize: '1.2rem'
      }}>
        Error loading products: {error}
      </div>
    );
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  return children;
};

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <PersistProvider>
      <AppWrapper>
        <App />
      </AppWrapper>
    </PersistProvider>
  </Provider>
);
